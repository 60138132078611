import React from "react"
import HighlightedProjects from "../components/homepage/HighlightedProjects"
import Powerpoint from "../images/ProjectTypes/ppt.svg"
import Forms from "../images/ProjectTypes/forms.svg"
import Support from "../images/ProjectTypes/support.svg"
import Games from "../images/ProjectTypes/games.svg"
import Website from "../images/ProjectTypes/website.svg"
import ScrollAnimation from "react-animate-on-scroll"
import Footer from "../components/headerAndFooter/Footer"
import Home from "../components/homepage/Home"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import WhyRemoteTA from "../components/homepage/WhyRemoteTA"
import Hackathons from "../components/homepage/Hackathons.js"
// import Schools from "../components/homepage/Schools.js"
import About from "../components/homepage/About.js"
import { Container, Row, Col } from "react-bootstrap"
import bgHome from "../images/bgs/bg1.svg"
import bgHome5 from "../images/bgs/bg5.svg"
import { Provider } from "react-redux"
import store from "../redux/Store"
import BackgroundImage from "../components/utils/BackgroundImage"
import Testimonials from "../components/homepage/Testimonials"

const IndexPage = () => (
  <Provider store={store}>
    <Layout noBackground>
      <SEO title="Home" />
      <Container fluid={true}>
        <BackgroundImage image={bgHome}>
          <Home />
          <Col xs={12}>
            <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "0px" }}>
              <Testimonials />
            </ScrollAnimation>
          </Col>
          <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "170px" }}>
            <About />
          </ScrollAnimation>
        </BackgroundImage>
        <BackgroundImage image={bgHome5}>
          <Col xs={12}>
            <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "170px" }}>
              <WhyRemoteTA />
            </ScrollAnimation>
          </Col>

          <Col xs={12}>
            <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "170px" }}>
              <HighlightedProjects
                titles={[
                  "Powerpoint Presentations",
                  "Forms or Flyers",
                  "Walkthroughs",
                  "Educational Games",
                  "Websites",
                ]}
                primaryDescriptions={[
                  "Our student volunteers have a lot of experience with PowerPoint! Teachers, if you provide us with your curriculum content, we can turn it into beautiful engaging slides!",
                  "Our student volunteers can create polished forms or gorgeous flyers for teachers and save precious time.",
                  "Our student volunteers with video editing skills can create easy-to-understand tutorials or walkthroughs that teachers can use to provide instructions to their students.",
                  "Our student volunteers with graphic design skills would love to work with teachers and help build creative educational tools or art projects to keep students engaged!",
                  "RemoteTA volunteers made this website from scratch — tell us your project needs and our student volunteers with coding skills can build you a beautiful website! ",
                ]}
                images={[Powerpoint, Forms, Support, Games, Website]}
              />
            </ScrollAnimation>
          </Col>
          <Col xs={12}>
            <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "170px" }}>
              <Hackathons />
            </ScrollAnimation>
          </Col>
          {/* <Col xs={12}>
            <ScrollAnimation animateIn="fadeIn" style={{ marginTop: "170px" }}>
              <Schools />
            </ScrollAnimation>
          </Col> */}
        </BackgroundImage>
        <Footer />
      </Container>
    </Layout>
  </Provider>
)

export default IndexPage
