import React from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Card } from "antd";
import { ChromeOutlined } from "@ant-design/icons";
import { Container, Row, Col } from "react-bootstrap";
import TitleLine from "../../components/utils/TitleLine";
import CovidGlobal from "../../images/CovidGlobal.png";
import TRC from "../../images/TRC.png";
import StatelessModal from "../../components/utils/StatelessModal";
import { toggleModal } from "../../redux/actions/ModalActions";
import {connect} from "react-redux"

function Hackathons(props) {
  const { Meta } = Card;

  return (
    <Container style={{ marginBottom: "100px" }}>
      <Row className="justify-content-center" style={{ marginBottom: "25px" }}>
        <StatelessModal>
          <h1>HI</h1>
        </StatelessModal>
        <TitleLine
          title="Our Awards"
          description="These are the awards RemoteTA has won to-date"
        />
      </Row>
      <Row className="justify-content-center">
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Card
            style={{ width: 300 }}
            cover={<img alt="example" src={CovidGlobal} />}
            actions={[
              <a
                href="https://covid-global-hackathon.devpost.com/submissions"
                target="_blank"
              >
                <ChromeOutlined />
              </a>,
            ]}
          >
            <Meta
              title="COVID-19 Global Hackathon"
              description="RemoteTA won this hackathon in April 2020. There were a total 19,000 participants and 1560 project submissions in this event."
            />
          </Card>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Card
            style={{ width: 300 }}
            cover={<img alt="example" src={TRC} />}
            actions={[
              <a
                href="https://theresiliencychallenge.devpost.com/submissions"
                target="_blank"
              >
                <ChromeOutlined />
              </a>,
            ]}
          >
            <Meta
              title="The Resiliency Challenge"
              description="RemoteTA won this hackathon in June 2020. There were a total of 700 participants and 43 project submissions in this event."
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default connect(null, { toggleModal })(Hackathons);
