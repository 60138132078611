import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { red } from "@material-ui/core/colors"
import { Container, Row, Col } from "react-bootstrap"
import "../../styles/GlobalStyles.css"
import TitleLine from "../../components/utils/TitleLine.js"

const useStyles = makeStyles(theme => ({
  root: {
    width: 345,
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

class HighlightedProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: [],
    }
  }
  componentDidMount() {
    let tExpanded = []
    for (let i = 0; i < this.props.titles.length; i++) {
      tExpanded.push(false)
    }

    this.setState({ expanded: tExpanded })
  }

  handleChange = i => {
    let tExpanded = this.state.expanded
    tExpanded[i] = !tExpanded[i]
    this.setState({ expanded: tExpanded })
  }

  render() {
    return (
      <HighlightedProjectsC
        titles={this.props.titles}
        images={this.props.images}
        primaryDescriptions={this.props.primaryDescriptions}
        fullDescription={this.props.fullDescription}
        expanded={this.state.expanded}
        handleChange={this.handleChange}
      />
    )
  }
}

function HighlightedProjectsC(props) {
  const classes = useStyles()

  let cols = []

  for (let i = 0; i < props.titles.length; i++) {
    cols.push(
      <Col
        className="globalPackage displayFlex"
        style={{ marginTop: "20px" }}
        lg={4}
      >
        <Card className={classes.root}>
          <CardHeader title={props.titles[i]} />
          <CardMedia
            className={classes.media}
            image={props.images[i]}
            title="Featured Project"
            style={{ backgroundSize: "80% 60%" }}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.primaryDescriptions[i]}
            </Typography>
          </CardContent>
        </Card>
      </Col>
    )
  }

  return (
    <Container style={{ marginTop: "-30px" }}>
      <div id="highlightedProjects"></div>
      <Row style={{ marginBottom: "60px" }}>
        <TitleLine
          title="Project Examples"
          description="These are some of the types of projects that teachers can post!"
        />
      </Row>
      <Row className="justify-content-center">
        {cols.map(function(col) {
          return col
        })}
      </Row>
    </Container>
  )
}

export default HighlightedProjects
