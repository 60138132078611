import React from "react"

export default function VideoPlayer(props) {
  return (
    <iframe
      id="video"
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: 20,
      }}
      src={"https://www.youtube.com/embed/" + props.videoId}
      frameBorder="0"
      allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
