import React from "react" // import react
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import TitleLine from "../../components/utils/TitleLine"

import Impact from "../../images/WhyRemoteTA/impact.svg"
import Flexible from "../../images/WhyRemoteTA/flexible.svg"
import Conv from "../../images/WhyRemoteTA/conv.svg"

import "../../styles/ihover.css"
import "../../styles/RegisterAsStudent.css"

const imagePadding = { padding: "10px" }

function WhyRemoteTA() {
  return (
    <>
      <Container fluid={true} style={{ marginBottom: "50px" }}>
        <div id="whyremoteta"></div>
        <TitleLine title="Why RemoteTA" />
        <Row className="justify-content-center center">
          <Col
            className="justify-content-center center tripletPadding tripletTop"
            xs={true}
            md={true}
            xl={3}
          >
            <Row className="justify-content-center">
              <div class="ih-item circle effect1">
                <a>
                  <div class="spinner"></div>
                  <div class="img">
                    <img
                      src={Flexible}
                      style={imagePadding}
                      class="imgMargin"
                      alt=""
                    />
                  </div>
                  <div class="info center">
                    <div class="info-back">
                      <h3 class="align1">FULL CONTROL OVER TIMING</h3>
                    </div>
                  </div>
                </a>
              </div>
            </Row>
            <Row className="justify-content-center">
              <span class="tripletHeading title">
                <br></br>
                Flexibility
              </span>
              <br></br>
              <br></br>
              <span class="tripletDescription description center">
                RemoteTA offers the flexibility to choose from a broad range of
                classroom projects. Students work on projects that fit their
                skill-set and busy schedules.
              </span>
            </Row>
          </Col>
          <Col
            className="justify-content-center center tripletPadding tripletTop"
            xs={true}
            md={true}
            xl={3}
          >
            <Row className="justify-content-center">
              <div class="ih-item circle effect1">
                <a>
                  <div class="spinner"></div>
                  <div class="img">
                    <img
                      src={Conv}
                      style={imagePadding}
                      class="imgMargin"
                      alt=""
                    />
                  </div>
                  <div class="info center">
                    <div class="info-back">
                      <h3 class="align2">VOLUNTEER WHENEVER, WHEREVER</h3>
                    </div>
                  </div>
                </a>
              </div>
            </Row>
            <Row className="justify-content-center">
              <span class="tripletHeading title">
                {" "}
                <br></br>
                Convenience
              </span>
              <br></br>
              <br></br>
              <span class="tripletDescription description center">
                RemoteTA is an online volunteering platform. Students work
                conveniently from their homes with no transportation hassles!
                The portal also offers a convenient way for students to maintain
                a record of their service hour log.
              </span>
            </Row>
          </Col>
          <Col
            className="justify-content-center center tripletPadding tripletTop"
            xs={true}
            md={true}
            xl={3}
          >
            <Row className="justify-content-center">
              <div class="ih-item circle effect1">
                <a>
                  <div class="spinner"></div>
                  <div class="img">
                    <img
                      src={Impact}
                      style={imagePadding}
                      class="imgMargin"
                      alt=""
                    />
                  </div>
                  <div class="info center">
                    <div class="info-back">
                      <h3
                        class="align3"
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}
                      >
                        RECEIVE VOLUNTEER HOURS FOR YOUR WORK
                      </h3>
                    </div>
                  </div>
                </a>
              </div>
            </Row>
            <Row className="justify-content-center">
              <span class="tripletHeading title">
                {" "}
                <br></br>
                Impact
              </span>
              <br></br>
              <br></br>
              <span class="tripletDescription description center">
                RemoteTA provides an innovative platform for students to give
                back to their school community. Students, working as remote
                Teacher's Assistants, also gain meaningful life-long skills.
              </span>
            </Row>
          </Col>
        </Row>
        <Row
          className="justify-content-center center"
          style={{ marginTop: "70px" }}
        >
          <Col className="justify-content-center" xl={3}>
            <Button variant="outline-primary" href="create-student-account">
              Register as a Student
            </Button>{" "}
          </Col>
          <Col className="justify-content-center" xl={3}>
            <Button variant="outline-primary" href="create-teacher-account">
              Register as a Teacher
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default WhyRemoteTA
