import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import {toggleModal} from "./../../redux/Actions/ModalActions"

function StatelessModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.toggleModal();
  };

  if (!open && props.displayModal) {
    setOpen(true);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props.children}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  const { ModalReducer } = state;
  return { displayModal: ModalReducer.displayModal };
};

export default connect(mapStateToProps, { toggleModal })(StatelessModal);
