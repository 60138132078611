import React from "react"
import "antd/dist/antd.css" // or 'antd/dist/antd.less'
import { Card } from "antd"
import { ChromeOutlined } from "@ant-design/icons"
import { Container, Row, Col } from "react-bootstrap"
import TitleLine from "../../components/utils/TitleLine"
import CovidGlobal from "../../images/CovidGlobal.png"
import TRC from "../../images/TRC.png"
import StatelessModal from "../../components/utils/StatelessModal"
import { toggleModal } from "../../redux/actions/ModalActions"
import { connect } from "react-redux"
import VideoPlayer from "../utils/VideoPlayer"

export default function Testimonials(props) {
  const { Meta } = Card

  return (
    <Container style={{ marginBottom: "100px" }}>
      <Row className="justify-content-center" style={{ marginBottom: "25px" }}>
        <TitleLine
          title="What is RemoteTA?"
          description="Hear feedback from RemoteTA's frequent users"
        />
      </Row>
      <Row className="justify-content-center">
        <Container
          style={{
            width: "100%",
            paddingTop: "56.25%",
            height: "0px",
            position: "relative"
          }}
        >
          <VideoPlayer videoId="lEb2wa9S1ps" />
        </Container>
      </Row>
    </Container>
  )
}
