import { MDBFooter } from "mdbreact"
import React from "react" // import react
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import "../../styles/Footer.css"
import { footerColor } from "../../constants"
function Footer() {
  return (
    <MDBFooter
      color={footerColor}
      className="font-small pt-4 mt-4 mx-n5 resetSize mainContainer"
    >
      <Container className="justify-content-center">
        <Row className="justify-content-center">
          <Col xl={4} className="center">
            <a
              style={{
                color: "white",
                marginBottom: "17px",
              }}
            >
              © 2020 Copyright: RemoteTA.org<br></br>
            </a>
          </Col>
          <Col xl={4} className="center">
            <a
              href="ContactUs"
              style={{
                color: "white",
                marginBottom: "17px",
              }}
            >
              <u>
                Contact Us<br></br>
              </u>
            </a>
          </Col>
          <Col
            xl={4}
            className="center"
            style={{ color: "white", marginBottom: "17px" }}
          >
            <a href="MeetTheTeam">
              <u>
                Meet The Team<br></br>
              </u>
            </a>
          </Col>
        </Row>
      </Container>
    </MDBFooter>
  )
}

export default Footer
