import React from "react"
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact"

import { Container, Row, Col } from "react-bootstrap"
import student from "../../images/About/student.svg"
import teacher from "../../images/About/teacher.svg"
import work from "../../images/About/work.svg"
import success from "../../images/About/success.svg"
import TitleLine from "../../components/utils/TitleLine"

const local = { maxWidth: "22rem", height: "25rem" }

const About = () => {
  return (
    <Container>
      <Row>
        <TitleLine title="How RemoteTA Works" description="" />
      </Row>
      <Row className="justify-content-center">
        <Col style={{ maxWidth: "22rem", marginTop: "15px" }} md={6}>
          <MDBCard style={local}>
            <MDBCardImage className="img-fluid" src={teacher} waves />
            <MDBCardBody>
              <MDBCardTitle>Teachers Post a Project</MDBCardTitle>
              <MDBCardText>
                Teachers register for a RemoteTA account and post projects that
                can be completed remotely by students
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Col>
        <Col style={{ maxWidth: "22rem", marginTop: "15px" }} md={6}>
          <MDBCard style={local}>
            <MDBCardImage className="img-fluid" src={student} waves />
            <MDBCardBody>
              <MDBCardTitle>Students Accept the Project</MDBCardTitle>
              <MDBCardText>
                Students register for a RemoteTA account, explore available
                projects, and express interest in projects that match their
                skill set
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Col>
        <Col style={{ maxWidth: "22rem", marginTop: "15px" }} md={6}>
          <MDBCard style={local}>
            <MDBCardImage className="img-fluid" src={work} waves />
            <MDBCardBody>
              <MDBCardTitle>Work and Communicate</MDBCardTitle>
              <MDBCardText>
                Students work with teachers to complete the projects. All
                student-teacher communication occurs remotely via email or video
                call. Students maintain a log of their service hours
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Col>
        <Col style={{ maxWidth: "22rem", marginTop: "15px" }} md={6}>
          <MDBCard style={local}>
            <MDBCardImage className="img-fluid" src={success} waves />
            <MDBCardBody>
              <MDBCardTitle>Project is Completed</MDBCardTitle>
              <MDBCardText>
                Using the RemoteTA portal, students request final project review
                and report their service hours. Teachers accept projects and
                awards service hours
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </Col>
      </Row>
    </Container>
  )
}

export default About
