import React from "react"
import "../../styles/Home.css"
import { Container, Row, Col, Button } from "react-bootstrap"
import TmpLogo from "../../images/logo.png"
import { navigate } from "gatsby-link"

function Home() {
  return (
    <>
      <Container
        fluid="xl"
        style={{ display: "flex", marginBottom: "250px", marginTop: "50px" }}
        className="justify-content-center"
      >
        <Row style={{ justifyContent: "center" }} className="center">
          <Col
            xl={5}
            xs={10}
            md={10}
            className="center"
            style={{ marginTop: "10px" }}
          >
            <img src={TmpLogo} alt=""></img>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "10px",
            }}
            xl={5}
            md={10}
            xs={10}
          >
            <Row className="justify-content-center">
              <span class="title" id="homeTitle">
                An Online Platform Connecting Teachers and Student Volunteers
              </span>
            </Row>
            <Row className="justify-content-center">
              <span id="subtext" class="description">
                Become a remote teacher's assistant (Remote TA) and help support
                classroom projects
              </span>
            </Row>
            <Row
              className="justify-content-center"
              style={{ marginTop: "10px" }}
            >
              <Button
                variant="#6C6FB2"
                onClick={() => navigate("/Join/")}
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  backgroundColor: "#6C6FB2",
                  color: "white",
                }}
              >
                Get Started!
              </Button>
            </Row>
          </Col>
        </Row>{" "}
      </Container>
      <div id="howitworks"></div>
    </>
  )
}
export default Home
